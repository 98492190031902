exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-cookies-jsx": () => import("./../../../src/pages/cookies.jsx" /* webpackChunkName: "component---src-pages-cookies-jsx" */),
  "component---src-pages-friskrivning-och-partnerskap-jsx": () => import("./../../../src/pages/friskrivning-och-partnerskap.jsx" /* webpackChunkName: "component---src-pages-friskrivning-och-partnerskap-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-integritetspolicy-jsx": () => import("./../../../src/pages/integritetspolicy.jsx" /* webpackChunkName: "component---src-pages-integritetspolicy-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-om-oss-jsx": () => import("./../../../src/pages/om-oss.jsx" /* webpackChunkName: "component---src-pages-om-oss-jsx" */),
  "component---src-pages-tack-jsx": () => import("./../../../src/pages/tack.jsx" /* webpackChunkName: "component---src-pages-tack-jsx" */),
  "component---src-templates-article-jsx": () => import("./../../../src/templates/Article.jsx" /* webpackChunkName: "component---src-templates-article-jsx" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/Category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */)
}

